import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiErrors } from '@nx-customer-apps/shared/enums';
import { LodashService, SnackbarService } from '@nx-customer-apps/shared/services';
import { ApiErrorsMessageDictionary } from '@nx-customer-apps/shared/utils';

@Injectable({
    providedIn: 'root'
})
export class ErrorService {
    constructor(private snackbarService: SnackbarService, private translate: TranslateService) {}

    public showSnackbar(key: ApiErrors, options?: { duration?: number }) {
        const messageKey = ApiErrorsMessageDictionary[key];
        const message = this.translate.instant(messageKey || ApiErrorsMessageDictionary[ApiErrors.Generic]);
        const duration = !LodashService.isNil(options?.duration) ? options?.duration : 5000;
        const action = this.translate.instant('COMMON.BUTTONS.GOT_IT');
        this.snackbarService.showInfo(message, {
            duration,
            action
        });
    }
}
