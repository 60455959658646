<div>
    <form (ngSubmit)="onSubmit()" [formGroup]="form" *ngIf="form">
        <div *ngFor="let question of questions">
            <vp-question [question]="question" [highlights]="highlights" [form]="form"></vp-question>
        </div>
        <div class="d-flex justify-content-end align-items-center">
            <div>
                <ng-content select="[bottomRightContent]"></ng-content>
            </div>
            <div>
                <button
                    *ngIf="!customConfirmEnabled"
                    type="submit"
                    mat-flat-button
                    class="ca-button ca-button-m d-flex ms-auto"
                    [caConfirm]="{
                        titleElement: confirmTitleElement,
                        contentElement: confirmContentElement,
                        cancelBtn: 'COMMON.BUTTONS.NO' | translate,
                        confirmBtn: 'COMMON.BUTTONS.YES' | translate,
                        disabled: !form.valid || !isValueDifferentThanCache || !cacheConfig?.updateOnConfim
                    }"
                    (confirm)="updateCacheAndSubmit()"
                    (cancel)="restoreValueFromCache()"
                >
                    <ng-container *ngTemplateOutlet="buttonTextElement"></ng-container>
                </button>
                <button
                    *ngIf="customConfirmEnabled"
                    type="button"
                    mat-flat-button
                    class="ca-button ca-button-m d-flex ms-auto"
                    [caConfirm]="customConfirm"
                    (confirm)="onSubmit()"
                >
                    <ng-container *ngTemplateOutlet="buttonTextElement"></ng-container>
                </button>
            </div>
        </div>
    </form>
</div>

<ng-template #buttonTextElement>
    <ng-content select="[buttonText]"></ng-content>
</ng-template>

<ng-template #confirmTitleElement>
    {{ 'CONFIRM.CALCULATION_PROGRESS.TITLE' | translate }}
</ng-template>

<ng-template #confirmContentElement>
    {{ 'CONFIRM.CALCULATION_PROGRESS.CONTENT' | translate }}
</ng-template>
