export enum RemoteConfigParameter {
    CreatePdfReportButtonVisible = 'createPdfReportButtonVisible',
    CountryDropdownByCountry = 'countryDropdownByCountry',
    CountryLanguageSwitcherByCountry = 'countryLanguageSwitcherByCountry',
    PdfConfiguratorBySection = 'pdfConfiguratorBySection',
    PdfConfiguratorGeneralInformationBySubsection = 'pdfConfiguratorGeneralInformationBySubsection',
    PdfConfiguratorHeatPumpPlanBySubsection = 'pdfConfiguratorHeatPumpPlanBySubsection',
    PdfConfiguratorPhotovoltaicPlanBySubsection = 'pdfConfiguratorPhotovoltaicPlanBySubsection',
    PdfConfiguratorVentilationPlanBySubsection = 'pdfConfiguratorVentilationPlanBySubsection',
    PdfConfiguratorFullSystemBySubsection = 'pdfConfiguratorFullSystemBySubsection',
    PdfConfiguratorLegalNoticeBySubsection = 'pdfConfiguratorLegalNoticeBySubsection',
    OnboardingVersion = 'onboardingVersion'
}
