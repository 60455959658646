<div class="dialog-header" mat-dialog-title data-testid="confirm-dialog-title">
    <h3 *ngIf="title" class="mat-display-small">
        {{ title }}
    </h3>
    <h3 *ngIf="titleElement" class="mat-display-small">
        <ng-container *ngTemplateOutlet="titleElement; context: { $implicit: context }"></ng-container>
    </h3>
    <button mat-icon-button class="ca-button close-button" data-testid="confirm-dialog-close-btn" (click)="close.emit()">
        <ca-icon icon="cross"></ca-icon>
    </button>
</div>
