<div
    [formGroup]="form"
    [ngClass]="{ 'question-margin': !question.validators.length, 'd-none': (question | as : TYPES.TextboxQuestion).hidden }"
>
    <div class="d-flex align-items-center" *ngIf="question.question">
        <h4 class="mb-3">{{ question.question | translate }}</h4>
        <ca-icon
            *ngIf="question.tooltip"
            icon="info-outline"
            class="ca-icon-s-18-p-0-f-18 ms-2 mb-3 vp-pointer"
            matTooltipPosition="above"
            matTooltipClass="ca-tooltip"
            [matTooltip]="question.tooltip | translate"
        ></ca-icon>
    </div>
    <div class="d-flex align-items-center" [class.mb-4]="question.img">
        <img class="description-img me-4" *ngIf="question.img" [src]="question.img" />
        <div>
            <p *ngIf="question.description" class="mb-3">{{ question.description | translate }}</p>
            <ul *ngIf="(question | as : TYPES.TextboxQuestion).descriptionList" class="mb-3 ps-4">
                <li *ngFor="let item of (question | as : TYPES.TextboxQuestion).descriptionList; trackBy: trackBySelf">
                    {{ item | translate }}
                </li>
            </ul>
            <p *ngIf="question.bottomDescription" class="mb-3">{{ question.bottomDescription | translate }}</p>
        </div>
    </div>
    <div [ngSwitch]="(question | as : TYPES.NotAGroup).controlType">
        <ng-container>
            <span *ngIf="(question | as : TYPES.TextboxQuestion).paragraph" class="me-2">
                {{ (question | as : TYPES.TextboxQuestion).paragraph | translate }}</span
            >
            <mat-form-field
                *ngSwitchCase="CONTROL_TYPES.Textbox"
                class="ca-form-field"
                [hideRequiredMarker]="true"
                appearance="outline"
                [ngClass]="question | as : TYPES.TextboxQuestion | formFieldStyles"
            >
                <mat-label *ngIf="(question | as : TYPES.TextboxQuestion).label" [attr.for]="(question | as : TYPES.TextboxQuestion).key">{{
                    (question | as : TYPES.TextboxQuestion).label | translate
                }}</mat-label>
                <input
                    [formControlName]="(question | as : TYPES.TextboxQuestion).key"
                    [placeholder]="(question | as : TYPES.TextboxQuestion).placeholder | translate"
                    [id]="(question | as : TYPES.TextboxQuestion).key"
                    [attr.data-testid]="(question | as : TYPES.TextboxQuestion).key"
                    [type]="(question | as : TYPES.TextboxQuestion).type"
                    [mask]="(question | as : TYPES.TextboxQuestion).mask.pattern"
                    [patterns]="$any(question).mask?.customPatterns"
                    (input)="onInput($event)"
                    (blur)="onBlur()"
                    [decimalMarker]="$any(question).mask?.decimalMarker"
                    [separatorLimit]="$any(question).mask?.separatorLimit"
                    [dropSpecialCharacters]="
                        ($any(question).mask?.dropSpecialCharacters | isNil) ? true : $any(question).mask?.dropSpecialCharacters
                    "
                    [suffix]="
                        (question | as : TYPES.TextboxQuestion).suffix
                            ? ' ' + ((question | as : TYPES.TextboxQuestion).suffix | translate)
                            : ''
                    "
                    matInput
                />
                <mat-hint *ngIf="hint"> {{ hint | translate }}</mat-hint>
                <mat-hint *ngIf="(question | as : TYPES.TextboxQuestion).permanentHint">
                    {{ (question | as : TYPES.TextboxQuestion).permanentHint | translate }}</mat-hint
                >
                <mat-hint
                    *ngIf="
                        (question | as : TYPES.TextboxQuestion).ifConditionalHint && (question | as : TYPES.TextboxQuestion).conditionalHint
                    "
                >
                    {{ (question | as : TYPES.TextboxQuestion).conditionalHint | translate }}</mat-hint
                >
                <mat-error *ngIf="isInvalid && errorMessage">
                    <ca-form-error-message [attr.data-testid]="(question | as : TYPES.TextboxQuestion).key + '-error-message'">
                        {{ errorMessage | translate }}
                    </ca-form-error-message>
                </mat-error>
            </mat-form-field>
            <span class="ms-1" *ngIf="(question | as : TYPES.TextboxQuestion).paragraph">.</span>
        </ng-container>

        <div *ngSwitchCase="CONTROL_TYPES.Radio">
            <mat-radio-group
                [formControlName]="(question | as : TYPES.RadioQuestion).key"
                appearance="outline"
                class="ca-radio-group"
                [id]="(question | as : TYPES.RadioQuestion).key"
                [attr.data-testid]="(question | as : TYPES.RadioQuestion).key"
                [ngClass]="question.styles"
            >
                <mat-radio-button
                    [disableRipple]="true"
                    *ngFor="let option of (question | as : TYPES.RadioQuestion).options; trackBy: trackByKey"
                    [id]="(question | as : TYPES.RadioQuestion).key + (option.id || option.value)"
                    [attr.data-testid]="(question | as : TYPES.RadioQuestion).key + (option.id || option.value)"
                    [disabled]="option.disabled"
                    [checked]="option.checked"
                    [value]="option.value"
                    class="d-inline-block"
                    [ngClass]="{ 'ca-radio-with-image': option.img && !option.icon, 'ca-radio-with-icon': option.icon && !option.img }"
                >
                    <ca-icon *ngIf="!option.img && option.icon" [icon]="option.icon" class="ca-icon-s-24-p-0-f-24"></ca-icon>
                    <img *ngIf="!option.icon && option.img" [src]="option.img" [alt]="option.key" class="mb-2" />
                    <span class="vp-truncate-text">{{ option.key | translate }}</span>
                    <span *ngIf="option.subtext && !option.img" class="d-block radio-subtext">{{ option.subtext | translate }}</span>
                </mat-radio-button>
            </mat-radio-group>
            <div *ngIf="question.validators?.length" class="vp-subscript position-relative mt-2">
                <div class="position-absolute vp-error-message" *ngIf="isInvalid && errorMessage" @slideDown>
                    <ca-form-error-message [attr.data-testid]="(question | as : TYPES.TextboxQuestion).key + '-error-message'">
                        {{ errorMessage | translate }}
                    </ca-form-error-message>
                </div>
            </div>
        </div>
        <vp-checkbox *ngSwitchCase="CONTROL_TYPES.Checkbox" [question]="question | as : TYPES.CheckboxQuestion" [form]="form"></vp-checkbox>
        <vp-checkbox-set
            *ngSwitchCase="CONTROL_TYPES.CheckboxSet"
            [question]="question | as : TYPES.CheckboxSet"
            [form]="form"
        ></vp-checkbox-set>
        <vp-multi-textbox
            *ngSwitchCase="CONTROL_TYPES.MultiTextbox"
            [question]="question | as : TYPES.MultiTextbox"
            [form]="form"
        ></vp-multi-textbox>
        <vp-custom-answer-radio
            *ngSwitchCase="CONTROL_TYPES.CustomAnswerRadio"
            [question]="question | as : TYPES.CustomAnswerRadio"
            [form]="form"
        >
        </vp-custom-answer-radio>
        <vp-slider
            [form]="form"
            [formControlName]="(question | as : TYPES.SliderQuestion).key"
            *ngSwitchCase="CONTROL_TYPES.Slider"
            [question]="question | as : TYPES.SliderQuestion"
        ></vp-slider>
        <vp-dropdown [form]="form" *ngSwitchCase="CONTROL_TYPES.Dropdown" [question]="question | as : TYPES.Dropdown"></vp-dropdown>
        <vp-search-dropdown
            [formGroup]="form"
            [formControlName]="(question | as : TYPES.SearchDropdown).key"
            *ngSwitchCase="CONTROL_TYPES.SearchDropdown"
            [question]="question | as : TYPES.SearchDropdown"
        ></vp-search-dropdown>
        <vp-addressbox
            [form]="form"
            *ngSwitchCase="CONTROL_TYPES.Addressbox"
            [highlights]="highlights"
            [question]="question | as : TYPES.Addressbox"
        ></vp-addressbox>
    </div>
</div>
