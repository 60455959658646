/**
 * Represents an application unique identifier that applications use to
 * identify themself in plannings.
 */
export enum ApplicationId {
    HeatPumpPlanner = 'HEAT_PUMP_PLANNER',
    PhotovoltaicPlanner = 'PHOTOVOLTAIC_PLANNER',
    VentilationPlanner = 'VENTILATION_PLANNER',
    CogenerationCalculator = 'COGENERATION_CALCULATOR',
    ElectricityPlanner = 'ELECTRICITY_PLANNER',
    SystemAdviser = 'SYSTEM_ADVISER',
    CylinderPlanner = 'CYLINDER_PLANNER',
    ViGuidePlanning = 'VIGUIDE_PLANNING',
    HomePlanner = 'HOME_PLANNER'
}
